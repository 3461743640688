// stake eth
const STAKE_ETH_TYPE = 'STAKE_ETH_TYPE'
const ETH_INPUT_CHANGE = 'INPUT_CHANGE'
const ETH_OUTPUT_CHANGE = 'ETH_OUTPUT_CHANGE'
const ETH_INFO = 'ETH_INFO'
const ETH_INFO_LOADING = 'ETH_INFO_LOADGING'

// stake lsd
const LSD_INFO = 'LSD_INFO'
const LSD_PERSONAL_INFO = 'LSD_PERSONAL_INFO'
const LSD_INFO_LOADING = 'LSD_INFO_LOADING'
const LSD_PERSONAL_INFO_LOADING = 'LSD_PERSONAL_INFO_LOADING'

// stake lp
const LP_INFO = 'LP_INFO'
const LP_PERSONAL_INFO = 'LP_PERSONAL_INFO'
const LP_INFO_LOADING = 'LP_INFO_LOADING'
const LP_PERSONAL_INFO_LOADING = 'LP_PERSONAL_INFO_LOADING'

export {
  STAKE_ETH_TYPE,
  ETH_INPUT_CHANGE,
  ETH_OUTPUT_CHANGE,
  ETH_INFO_LOADING,
  ETH_INFO,
  LSD_INFO,
  LSD_INFO_LOADING,
  LSD_PERSONAL_INFO_LOADING,
  LSD_PERSONAL_INFO,
  LP_INFO,
  LP_PERSONAL_INFO,
  LP_INFO_LOADING,
  LP_PERSONAL_INFO_LOADING
}